import React, { useEffect, useState } from 'react';
import './DashboardPage.css'; // CSS for styling

function DashboardPage() {
    const [ticketStatus, setTicketStatus] = useState([]);
    const [dealStatus, setDealStatus] = useState([]);

    // Simulate fetching data from an API
    useEffect(() => {
        // Replace this with a real API call
        const fetchTicketStatus = async () => {
            const ticketData = [
                { id: 'NS10SJSJ124BBB', status: 'Pending' },
                { id: 'NS10SJSJ124BBA', status: 'Gagal' },
                { id: 'NS10SJSJ124BBC', status: 'Sukses' },
                { id: 'NS10SJSJ124BBD', status: 'Pending' },
                { id: 'NS10SJSJ124BBE', status: 'Pending' }
            ];
            setTicketStatus(ticketData);
        };

        const fetchDealStatus = async () => {
            const dealData = [
                { id: 'NS10SJSJ124BBD', status: 'Pending' },
                { id: 'NS10SJSJ124BBE', status: 'Gagal' },
                { id: 'NS10SJSJ124BBF', status: 'Sukses' },
            ];
            setDealStatus(dealData);
        };

        fetchTicketStatus();
        fetchDealStatus();
    }, []);

    const getStatusClass = (status) => {
        switch (status) {
            case 'Pending':
                return 'status-pending';
            case 'Gagal':
                return 'status-failed';
            case 'Sukses':
                return 'status-success';
            default:
                return '';
        }
    };

    return (
        <div className="dashboard-page">
            <main className="dashboard-content">
                <section>
                    <h1>Dashboard</h1>
                    <div className="status-section">
                        <div className="ticket-title">
                            <h3>Ticket Status</h3>
                            <p style={{color: '#FD4809', textDecoration: 'underline', fontSize: '16px', cursor:'pointer'}}>See more ticket</p>
                        </div>
                        <div className="status-grid">
                            {ticketStatus.map((ticket) => (
                                <div key={ticket.id} className={"status-card"}>
                                    <div className={`ticket-status ${getStatusClass(ticket.status)}`}>{ticket.status}</div>
                                    <div className="dashboard-ticket-content">
                                        <p>Customer</p>
                                        <p><b>PT. ASUS</b></p>
                                        <p>ID: <b>{ticket.id}</b></p>
                                        <div className={"ticket-priority"}>
                                            <p>Priority:</p>
                                            <div className={"priority"}>
                                                <p>Normal</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="ticket-title">
                            <h3>Deal Registration Status</h3>
                            <p style={{color: '#FD4809', textDecoration: 'underline', fontSize: '16px', cursor:'pointer'}}>See more deal registration</p>
                        </div>
                        <div className="deal-grid">
                            {dealStatus.map((deal) => (
                                <div key={deal.id} className={`deal-card`}>
                                    <b>SIEM - TKMT Risk Management</b>
                                    <hr style={{width: '100%', color: 'rgba(0, 0, 0, 0.04)'}}/>
                                    <p style={{fontStyle: 'italic', fontWeight: '600px', color: 'rgba(0, 0, 0, 0.5)', fontSize: '11px'}}>Reseller</p>
                                    <p>John Doe</p>
                                    <p style={{fontWeight: 'bold'}}>johndoe@gmail.com</p>
                                    <p>081212121212</p>
                                    <hr style={{width: '100%', color: 'rgba(0, 0, 0, 0.04)'}}/>
                                    <p style={{fontStyle: 'italic', fontWeight: '600px', color: 'rgba(0, 0, 0, 0.5)', fontSize: '11px'}}>Customer</p>
                                    <p style={{fontWeight: 'bold'}}>Stoner</p>
                                    <p>081212121212</p>
                                    <p>ID: {deal.id}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default DashboardPage;
