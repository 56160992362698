import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../App.css';
import { useNavigate } from 'react-router-dom';


function Sidebar() {
    const navigate = useNavigate();
    const logout = () => {
        window.localStorage.removeItem('isAuthenticated')
        window.localStorage.removeItem('accesToken')
        window.localStorage.removeItem('refreshToken')
        navigate('/login')
    }

    return (
        <aside className="sidebar">
            <div className="logo">
                <h1>TKMT</h1>
                <p>Risk Management</p>
            </div>
            <nav className="sidebar-menu">
                <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'menu-item active' : 'menu-item')}>Dashboard</NavLink>
                <NavLink to="/deal-registration" className={({ isActive }) => (isActive ? 'menu-item active' : 'menu-item')}>Deal Registration</NavLink>
                <NavLink to="/ticketing" className={({ isActive }) => (isActive ? 'menu-item active' : 'menu-item')}>Ticketing</NavLink>
                {/*<Link to="/opportunity" className="menu-item">Opportunity</Link>*/}
                {/*<Link to="/university" className="menu-item">TKMT University</Link>*/}
            </nav>
            <div className="logout">
                <p onClick={logout} style={{cursor:'pointer'}}>Logout</p>
            </div>
        </aside>
    );
}

export default Sidebar;
