import React from 'react';
import './TicketingPage.css'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Chip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

const tableData = [
    {
        customer: 'JVC Electronics',
        entitlement: 'SLA',
        category: 'Incident Technical Support',
        status: 'In Progress',
        priority: 'Normal',
        problemID: '2022-61fcce...',
        description: '#Issue - Aplikasi error',
        createDate: '04-02-2022',
    },
    {
        customer: 'JVC Electronics',
        entitlement: 'SLA',
        category: 'Incident Technical Support',
        status: 'Registration',
        priority: 'High',
        problemID: '2022-61fcce...',
        description: '#Issue - Aplikasi error',
        createDate: '04-02-2022',
    },
    {
        customer: 'JVC Electronics',
        entitlement: 'SLA',
        category: 'Incident Technical Support',
        status: 'Registration',
        priority: 'Low',
        problemID: '2022-61fcce...',
        description: '#Issue - Aplikasi error',
        createDate: '04-02-2022',
    },
    {
        customer: 'JVC Electronics',
        entitlement: 'SLA',
        category: 'Incident Technical Support',
        status: 'Registration',
        priority: 'Cancelled',
        problemID: '2022-61fcce...',
        description: '#Issue - Aplikasi error',
        createDate: '04-02-2022',
    },
    {
        customer: 'JVC Electronics',
        entitlement: 'SLA',
        category: 'Incident Technical Support',
        status: 'Open',
        priority: 'Normal',
        problemID: '2022-61fcce...',
        description: '#Issue - Aplikasi error',
        createDate: '04-02-2022',
    },
    // Add more rows as needed
];

const getStatusChip = (status) => {
    switch (status) {
        case 'In Progress':
            return 'in-progress'
        case 'Registration':
            return 'registration'
        case 'Open':
            return 'open'
        default:
            return '';
    }
};

const getPriorityChip = (priority) => {
    switch (priority) {
        case 'High':
            return 'high';
        case 'Normal':
            return 'normal';
        case 'Low':
            return 'low';
        case 'Cancelled':
            return 'cancelled';
        default:
            return '';
    }
};

const getEntitlement = (entitlement) => {
    switch (entitlement) {
        case 'SLA':
            return 'SLA';
        default:
            return '';
    }
}

function TicketingPage() {
    return (
        <div className={"ticket-content"}>
            <h1>Ticketing</h1>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Customer</TableCell>
                            <TableCell>Entitlement</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Priority</TableCell>
                            <TableCell>Problem ID</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Create Date</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.customer}</TableCell>
                                <TableCell><div className={`entitlement-chip ${getEntitlement(row.entitlement)}`}>{getEntitlement(row.entitlement)}</div> </TableCell>
                                <TableCell>{row.category}</TableCell>
                                <TableCell><div className={`status-chip ${getStatusChip(row.status)}`}>{getStatusChip(row.status)}</div></TableCell>
                                <TableCell><div className={`priority-chip ${getPriorityChip(row.priority)}`}>{getPriorityChip(row.priority)}</div></TableCell>
                                <TableCell>{row.problemID}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.createDate}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" aria-label="view">
                                        <VisibilityIcon />
                                    </IconButton>
                                    <IconButton color="secondary" aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="error" aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default TicketingPage;
