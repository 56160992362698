import React from 'react';
import './ProfilePage.css'

function ProfilePage() {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [profileImage, setProfileImage] = React.useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState(require('../..//assets/avatar.png'));

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfileImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle image upload to the API
    const handleImageUpload = async () => {
        const formData = new FormData();
        formData.append('profileImage', profileImage);

        try {
            // const response = await axios.post('/api/upload-profile', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     },
            // });
            // console.log('Image uploaded successfully:', response.data);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };


    return (
        <div className={"profile-page"}>
            <h1>Profile</h1>
            <div className={"profile-content"}>
                <div className={"account-settings"}>
                    <h3>Account Settings</h3>
                    <form className={"account-settings-form"}>
                        <p>First Name</p>
                        <input type="text" placeholder={"First Name"} value={firstName}
                               onChange={e => setFirstName(e.target.value)} required/>
                        <p>Last Name</p>
                        <input type="text" placeholder={"Last Name"} value={lastName}
                               onChange={e => setLastName(e.target.value)} required/>
                        <p>Email</p>
                        <input type={"email"} placeholder={"Email"} value={email}
                               onChange={e => setEmail(e.target.value)} required/>
                        <p>Phone Number</p>
                        <input type={"number"} placeholder={"Phone number"} value={phone}
                               onChange={e => setPhone(e.target.value)} required/>
                        <button type="submit" className={"submit-btn"}>Submit</button>
                    </form>
                </div>
                <div className={"password-settings"}>
                    <h3>Change Password</h3>
                    <form className={"account-settings-form"}>
                        <p>Current Password</p>
                        <input type="password" placeholder={"Current Password"} value={password}
                               onChange={e => setPassword(e.target.value)} required/>
                        <p>New Password</p>
                        <input type={"password"} placeholder={"New Password"} value={newPassword}
                               onChange={e => setNewPassword(e.target.value)} required/>
                        <p>Confirm Password</p>
                        <input type={"password"} placeholder={"Confirm New Password"} value={confirmPassword}
                               onChange={e => setConfirmPassword(e.target.value)} required/>
                        <button type="submit" className={"submit-btn"}>Submit</button>
                    </form>
                </div>
            </div>
            <div className="profile-picture-container" style={{display: 'flex', alignItems: 'center'}}>
                <div>
                    <img
                        src={imagePreviewUrl}
                        alt="Profile"
                        style={{width: '100px', height: '100px', borderRadius: '8px'}}
                    />
                </div>
                <div style={{marginLeft: '20px'}}>
                    <p>Image size should be under 5MB</p>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{display: 'none'}}
                        id="file-input"
                    />
                    <label htmlFor="file-input" style={{color: 'orange', cursor: 'pointer'}}>
                        Change photo
                    </label>
                    {profileImage && (
                        <button onClick={handleImageUpload} style={{marginLeft: '10px'}}>
                            Upload
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ProfilePage;