import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import TicketingPage from './pages/TicketingPage/TicketingPage';
import DealRegistrationPage from './pages/DealRegistrationPage/DealRegistrationPage';
// import OpportunityPage from './pages/OpportunityPage';
// import UniversityPage from './pages/UniversityPage';
import LoginPage from './pages/LoginPage/LoginPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import Sidebar from './components/Sidebar/Sidebar';
import './App.css';
import Header from "./components/Header/Header";
import ProtectedRoute from "./components/ProtectedRoute";
import {Bounce, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route element={<ProtectedRoute />}>
                    <Route
                        path="*"
                        element={
                            <div className="app">
                                <Sidebar />
                                <div className="main-content">
                                    <Header />
                                    <Routes>
                                        <Route path="/dashboard" element={<DashboardPage />} />
                                        <Route path="/deal-registration" element={<DealRegistrationPage />} />
                                        <Route path="/ticketing" element={<TicketingPage />} />
                                        <Route path="/profile" element={<ProfilePage />} />
                                    </Routes>
                                </div>
                            </div>
                        }
                    />
                </Route>
            </Routes>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
                containerId="GlobalApplicationToast"
            />
            <ToastContainer />
        </Router>
    );
}

export default App;
