import React, {useState} from "react";
import './DealRegistrationPage.css'
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, TableSortLabel, InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const dealData = [
    {
        product: 'SIEM - TKM',
        resellerName: 'John Doi',
        resellerEmail: 'johndoe@example.com',
        resellerPIC: 'Max Biaggi',
        resellerPhoneNumber: '081234567891',
        customerName: 'Stoner',
        customerPIC: 'Pedrosa',
        customerPhoneNumber: '081244559843',
    },
    // Duplicate data for illustration purposes
    ...Array(8).fill({
        product: 'SIEM - TKM',
        resellerName: 'John Doe',
        resellerEmail: 'johndoe@example.com',
        resellerPIC: 'Max Biaggi',
        resellerPhoneNumber: '081234567890',
        customerName: 'Stoner',
        customerPIC: 'Pedrosa',
        customerPhoneNumber: '081244559843',
    }),
];

function DealRegistrationPage() {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = [...dealData].sort((a, b) => {
        if (sortConfig.key) {
            const aValue = a[sortConfig.key] || '';  // Set to empty string if undefined
            const bValue = b[sortConfig.key] || '';  // Set to empty string if undefined
            const directionFactor = sortConfig.direction === 'asc' ? 1 : -1;
            return aValue.localeCompare(bValue) * directionFactor;
        }
        return 0;
    });

    const filteredData = sortedData.filter((deal) => {
        return Object.values(deal).some((val) =>
            val.toLowerCase().includes(searchTerm)
        );
    });

    return (
        <div className={"deal-registration-page"}>
            <main className={"deal-registration-content"}>
                <section>
                    <div className={"deal-registration-content-header"}>
                        <h1>Deal Registration</h1>
                            <TextField
                                variant="outlined"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                sx={{marginBottom: '16px', height: '38px', '& .MuiInputBase-root': {height: '38px', backgroundColor: 'white', borderWidth: 0}}}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon /> {/* This adds the magnifying glass icon */}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                    </div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{backgroundColor: '#F9F8F9'}}>
                                        {['product', 'resellerName', 'resellerEmail', 'resellerPIC', 'resellerPhoneNumber', 'customerName', 'customerPIC', 'customerPhoneNumber'].map((header) => (
                                            <TableCell key={header}>
                                                <TableSortLabel
                                                    active={sortConfig.key === header}
                                                    direction={sortConfig.direction}
                                                    onClick={() => handleSort(header)}
                                                >
                                                    {header.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData.map((deal, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{deal.product}</TableCell>
                                            <TableCell>{deal.resellerName}</TableCell>
                                            <TableCell>{deal.resellerEmail}</TableCell>
                                            <TableCell>{deal.resellerPIC}</TableCell>
                                            <TableCell>{deal.resellerPhoneNumber}</TableCell>
                                            <TableCell>{deal.customerName}</TableCell>
                                            <TableCell>{deal.customerPIC}</TableCell>
                                            <TableCell>{deal.customerPhoneNumber}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                </section>
            </main>
        </div>
    )
}

export default DealRegistrationPage;