import axios from "axios";

const tkmInstance = axios.create({
    baseURL: "https://dashboard.tkm-teknologi.id",
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        "Accept": "application/json",
    }, transformRequest: [(data, headers) => {
        if(data){
            if(data.data)
                return {...data.data};
            return data;
        }
        return data;
    }, ...axios.defaults.transformRequest]
})

const tkmInstanceWithoutHeader = axios.create({
    baseURL: "https://dashboard.tkm-teknologi.id",
    // timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    transformRequest: [(data, headers) => {
        if(data){
            if(data.data)
                return {...data.data}
            else
                return data
        }

        return data
    }, ...axios.defaults.transformRequest]
})

tkmInstance.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error.config;
        if(error.response.status === 401 && originalRequest.url === 'token/refresh'){
            window.location.href = '/login';
            return Promise.reject(error);
        }

        if(error.response.status === 401 && !originalRequest._retry){
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            return tkmInstance.post('/token/refresh', {refreshToken: refreshToken}).then((response) => {
                localStorage.setItem('accessToken', response.data.access);
                tkmInstance.defaults.headers['Authorization'] = "Bearer" + response.data.access;
                originalRequest.headers['Authorization'] = "Bearer" + response.data.access;
                return tkmInstance(originalRequest);
                }
            ).catch(error => {})
        }
        return Promise.reject(error);
    }
)

export const obtainToken = (data) => {
    return tkmInstanceWithoutHeader.post('auth/login', data);
}

export const register = (data) => {
    return tkmInstanceWithoutHeader.post('auth/register', data);
}