import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';


function Header() {
    const navigate = useNavigate();
    return (
        <header className={'header'}>
            <div className="welcome">
                <h3>Welcome, Alexa A.</h3>
                <p>Have a good day!</p>
            </div>
            <img
                src="https://via.placeholder.com/40" // Replace with the actual image source
                alt="User Avatar"
                className="avatar"
                onClick={() => navigate('/profile')}
                style={{cursor: 'pointer'}}
            />
        </header>
    )
}
export default Header;